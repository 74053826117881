<template>
  <v-container>
    <div v-for="section in menu.items" :key="section.heading">
      <div v-if="section.items.some(e => e.role.includes(auth.user.role.name))">
        <div v-if="section.heading">
          <v-subheader>{{section.heading}}</v-subheader>
          <v-row>
            <v-col cols="6" sm="4" md="3" lg="2" v-for="item in section.items" :key="item.title">
              <div v-if="item.role.includes(auth.user.role.name)">
                <v-hover v-slot="{ hover }">
                  <v-card v-on:click="gotoPage(item.page)" :elevation="hover ? 12 : 2" :disabled="item.disabled">
                    <v-icon size="100" class="mt-4">{{item.icon}}</v-icon>
                    <v-card-title class="pt-0">{{item.title}}</v-card-title>
                  </v-card>
                </v-hover>
              </div>
            </v-col>
          </v-row>
          <v-divider class="mt-4"></v-divider>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
export default {
  data: function() {
    return {
    }
  },
  mounted() {
  },
  methods: {
    gotoPage: function(page){
      var _this = this;
      
      var idx = 0;
      this.menu.items.forEach(sections => {
        sections.items.forEach(item => {
          if(item.page == page){
            _this.menu.value = idx;
          }
          idx++;
        });
      });
      this.navTo(page);
    }
  }
}
</script>